<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search students"
            type="text"
          />
          <b-button
            v-b-modal.addStudentModal
            variant="primary"
          >
            <feather-icon icon="PlusIcon" />
            Add Student
          </b-button>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="students"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'title'"
            class="d-flex justify-content-between align-items-start"
          >
            {{ props.row.title }}
            <b-button
              variant="outline-primary"
              size="sm"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('requestDetailsModal')"
            >View</b-button>
          </span>
          <span v-else-if="props.column.field === 'priority'">
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field])"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
          </span>

          <span
            v-else-if="props.column.field === 'assignee_status'"
            class="d-flex justify-content-between align-items-center"
          >
            <b-badge
              class="text-capitalize"
              :variant="statusVariant(`${props.formattedRow[props.column.field]}`.toLowerCase())"
            >{{ props.formattedRow[props.column.field] }}</b-badge></span>

          <span
            v-else-if="props.column.field === 'status'"
            class="d-flex justify-content-between align-items-center"
          >
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field].toLowerCase())"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
            <b-dropdown
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
              no-caret
            >

              <template #button-content>
                <span class="align-middle text-nowrap">Update Status<feather-icon
                  class="align-middle ml-25"
                  icon="ChevronDownIcon"
                /></span>
              </template>
              <b-dropdown-item
                variant="primary"
                :disabled="props.row.status === 'in-process'"
                @click="updateRequestStatus(props.row.requesttable_id, 'in-process')"
              >
                <feather-icon
                  class="mr-50"
                  icon="ClockIcon"
                />
                <span>In-Process</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="success"
                :disabled="props.row.status === 'resolved'"
                @click="updateRequestStatus(props.row.requesttable_id, 'resolved')"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckIcon"
                />
                <span>Resolved</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                :disabled="props.row.status === 'closed'"
                @click="updateRequestStatus(props.row.requesttable_id, 'closed')"
              >
                <feather-icon
                  class="mr-50"
                  icon="XIcon"
                />
                <span>Closed</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        v-if="students[selectedRow] && students[selectedRow].hasOwnProperty('description') "
        id="requestDetailsModal"
        hide-footer
        title="Details"
      >
        <div>
          <p>
            {{ students[selectedRow].description }}
          </p>
        </div>
      </b-modal>
      <b-modal
        id="addStudentModal"
        title="Add Student"
        ok-title="Add"
        @ok="addStudent"
      >
        <b-row>

          <b-col cols="12">
            <b-form-group
              label="Purpose"
              label-for="purpose"
            >
              <b-form-input
                id="purpose"
                v-model="student.title"
                placeholder="Purpose"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="student.description"
                placeholder="Description"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Priority"
              label-for="priority"
            >
              <v-select
                id="priority"
                v-model="student.priority"
                class="text-capitalize"
                :options="['low','medium','high','urgent']"
                placeholder="Priority"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Select Student"
              label-for="requester"
            >
              <v-select
                id="requester"
                v-model="student.requester_id"
                :reduce="option => option.id"
                :get-option-label="option => option.users_customuser.full_name"
                :options="studentsList"
                placeholder="Select Student"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/utils/auth'

export default {
  components: {
    BBadge,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Purpose',
          field: 'title',
        },
        {
          label: 'Student Name',
          field(row) {
            return row.usersAssociationtableByRequester.users_customuser.full_name
          },
        },
        {
          label: 'Mentor Status',
          field: 'assignee_status',
          tdClass: 'text-capitalize',
        },

        {
          label: 'Priority',
          field: 'priority',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Created on',
          field: 'created_at',
          formatFn(date) {
            return new Date(date).toLocaleDateString('en-IN', { dateStyle: 'medium' })
          },
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-capitalize',
        },
      ],
      student: {
        title: '',
        description: '',
        priority: '',
        requester_id: '',
      },
      students: [],
      studentsList: [], // list of all students of incubator
      mutationLoading: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'light-success',
        medium: 'light-info',
        high: 'light-warning',
        urgent: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {

    addStudent() {
      // Apollo mutate add request
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation addRequest($title: String!, $description: String!, $priority: String!, $incubator_id: Int!, $assignee_id: Int!, $requester_id: Int!) {
            insert_requesttable_one(object: {title: $title, description: $description, priority: $priority, incubator_id: $incubator_id, assignee_id: $assignee_id, requester_id: $requester_id, is_mentor: true, status: "assigned", assignee_status: "unchecked"}) {
              requesttable_id
            }
          },
        `,
        variables: {
          title: this.student.title,
          description: this.student.description,
          priority: this.student.priority,
          requester_id: this.student.requester_id,
          assignee_id: this.$route.params.id,
          incubator_id: getUserData()
            .associatedOrgDetails
            .filter(e => e.role === 'superadmin')[0].organization_id,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student added successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.request = {
            title: '',
            description: '',
            priority: '',
            assignee_id: '',
          }
          this.mutationLoading = false
          this.$apollo.queries.students.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding student',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.students.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
  },
  apollo: {
    students: {
      query() {
        return gql`
           query student_assignment_in_mentor {
            requesttable(where: {
                is_mentor: { _eq: true } ,
                assignee_id: {_eq: ${this.$route.params.id}},
                usersAssociationtableByRequester: {users_customuser: {id: {_is_null: false}}}
            }) {
              created_at
              description
              priority
              status
              title
              usersAssociationtableByRequester {
                users_customuser{
                  id
                  full_name
                }
              }
              assignee_status
              requesttable_id
            }
         }`
      },
      update: data => data.requesttable,
    },
    // students list
    studentsList: {
      query() {
        return gql`
          query students($incubator_id: Int!) {
            users_associationtable(
    where: {incubator_id: {_eq: $incubator_id}, role: {_eq: "student"}}
  ) {
    id
    status
    users_customuser {
      full_name
      linkedin_profile
      phone_number
              }
            }
          }`
      },
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update: data => data.users_associationtable,
    },
  },
}
</script>
